<template>
  <v-row class="kitchen-bio-panel">
    <!-- kitchen profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="kitchenData.avatar ? '' : 'primary'"
            :class="kitchenData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img :src="kitchenData.image"></v-img>
          </v-avatar>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">kitchen Name:</span>
              <span class="text--secondary">{{ kitchenData.name }}</span>
            </v-list-item>
            
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Provider Username:</span>
              <span class="text--secondary">{{ kitchenData.username }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Mobile Number:</span>
              <span class="text--secondary">{{ kitchenData.contact_person }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Emergency Number:</span>
              <span class="text--secondary">{{ kitchenData.emergency_number }}</span>
            </v-list-item>

            <div class="d-flex table-container flex-wrap">
              <div class="table">
                <!-- location info -->
                <table border="" class="mt-6">
                  <thead>
                    <tr align="center">
                      <th style="min-width: 200px" colspan="2">Location Info</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="px-2">Google Map Link</td>
                      <td align="center">
                        <v-btn @click="opedLink(kitchenData.location_url)"> Go </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td class="px-2">Street Name</td>
                      <td align="center">{{ kitchenData.street_name }}</td>
                    </tr>
                    <tr>
                      <td class="px-2">Building Number</td>
                      <td align="center">{{ kitchenData.building_number }}</td>
                    </tr>
                    <tr>
                      <td class="px-2">Latitude</td>
                      <td align="center">{{ kitchenData.latitude }}</td>
                    </tr>
                    <tr>
                      <td class="px-2">Longitude</td>
                      <td align="center">{{ kitchenData.longitude }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table">
                <!-- Working Days info -->
                <table border="" class="mt-6">
                  <thead>
                    <tr align="center">
                      <th style="min-width: 200px" colspan="3">Working Days</th>
                    </tr>
                    <tr align="center">
                      <th style="min-width: 200px">Day</th>
                      <th style="min-width: 200px">start work time</th>
                      <th style="min-width: 200px">end work time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="day in kitchenData.work_days" :key="day">
                      <td align="center" class="px-2">{{ day }}</td>
                      <td align="center">
                        {{ kitchenData.start_work_time }}
                      </td>
                      <td align="center">
                        {{ kitchenData.end_work_time }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <v-list-item dense class="px-0 mt-10" v-if="kitchenData.chiefs">
              <span class="font-weight-medium me-2">Total Number of Chefs:</span>
              <span class="text--secondary">{{ kitchenData.chiefs.length }}</span>
            </v-list-item>
            <!-- table -->
            <v-data-table
              id="table"
              :headers="tableColumnsForChiefs"
              :items="kitchenData.chiefs"
              class="mt-10"
            >
              <template v-slot:top>
                <v-toolbar rounded short dense color="rgb(104 82 215 / 28%)" flat>
                  <v-toolbar-title class="font-weight-medium me-2 text-capitalize">chefs Table</v-toolbar-title>
                </v-toolbar>
              </template>

              <!-- actions -->
              <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item :to="{ name: 'apps-chef-view', params: { id: item.id } }">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2">
                          {{ icons.mdiFileDocumentOutline }}
                        </v-icon>
                        <span>Details</span>
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item link @click="handleDelete(item.id)">
                      <v-list-item-title>
                        <v-icon size="20" class="me-2">
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                        <span>Delete</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>

            <!-- <v-card-actions class="justify-center pt-5">
              <v-btn color="primary" class="me-3" @click="isBioDialogOpen = !isBioDialogOpen"> Edit </v-btn>
              <v-btn color="error" outlined @click="handleDelete(kitchenData.id)"> Delete </v-btn>
            </v-card-actions> -->
          </v-list>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <kitchen-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :kitchen-data="kitchenData"
        @refresh="updatekitchenData"
      ></kitchen-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle, mdiStarOutline,mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import usekitchensList from '../../kitchens-list/useKitchensList'
import kitchenBioEdit from './KitchenBioEdit.vue'

export default {
  components: {
    kitchenBioEdit,
  },
  props: {
    kitchenData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updatekitchenData(newValue) {
      this.$emit('refresh', newValue)
    },
  },
  setup() {
    const { tableColumnsForChiefs } = usekitchensList()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-kitchen/deletekitchen', id)
            .then(() => {
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
              router.push({ name: 'apps-kitchens-list' })
            })
            .catch(response => {
              console.error(response)
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.message,
                footer: 'This kitchen might have items that needs to be deleted first',
              })
            })
        }
      })
    }

    const opedLink = link => {
      window.open(link)
    }
    return {
      tableColumnsForChiefs,
      opedLink,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,
      handleDelete,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiDotsVertical,
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiStarOutline,
      },
    }
  },
}
</script>
<style scoped>
.table-container {
  justify-content: center;
  column-gap: 5rem;
}
.table {
  overflow: auto;
}
td {
  padding: 1rem;
}
</style>
