<template>
  <div id="kitchen-view">
    <v-row>
      <v-col cols="12">
        <kitchen-bio-panel
          v-if="kitchenData"
          :kitchen-data="kitchenData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refresh="udpatekitchenData"
        ></kitchen-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline } from '@mdi/js'
import kitchenStoreModule from '../kitchenStoreModule'
import kitchenBioPanel from './kitchen-bio-panel/KitchenBioPanel.vue'

export default {
  components: {
    kitchenBioPanel,
  },
  setup() {
    const kitchen_APP_STORE_MODULE_NAME = 'app-kitchen'

    // Register module
    if (!store.hasModule(kitchen_APP_STORE_MODULE_NAME))
      store.registerModule(kitchen_APP_STORE_MODULE_NAME, kitchenStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(kitchen_APP_STORE_MODULE_NAME)) store.unregisterModule(kitchen_APP_STORE_MODULE_NAME)
    })

    const kitchenData = ref(null)
    const kitchenTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    const udpatekitchenData = () => {
      store
        .dispatch('app-kitchen/fetchkitchen', { id: router.currentRoute.params.id })
        .then(response => {
          kitchenData.value = response.data.data
          selectedPlan.value = response.data.currentPlan
          console.log('kitchenData.value', kitchenData.value)
        })
        .catch(error => {
          if (error.response.status === 404) {
            kitchenData.value = {}
          }
        })
    }
    udpatekitchenData()

    const tabs = [{ icon: mdiAccountOutline, title: 'Overview' }]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      udpatekitchenData,
      tabs,
      kitchenTab,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      kitchenData,
      resolveCurrentPlanValue,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
