<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="800px"
    @click:outside="$emit('update:is-bio-dialog-open', false), cancel()"
  >
    <v-card class="chef-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit Chef Information </v-card-title>
      <v-card-text class="text-center mt-n2"> Updating Chef details will receive a privacy audit. </v-card-text>

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="kitchenDataLocal.name"
                outlined
                dense
                label="kitchen Name"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-row class="mb-6" v-if="showImage">
                <v-file-input
                  class="file"
                  v-model="kitchenDataLocal.image"
                  :rules="[validators.required, fileAccept(['jpg', 'jpeg', 'png'], kitchenDataLocal.image)]"
                  :prepend-icon="icons.mdiCamera"
                  accept=".jpg, .jpeg, .png"
                  label="Upload Image (.jpg, .jpeg, .png)"
                  hint="Required Image dimensions: width:100px, height:100px, max-file-size: 500kb"
                  persistent-hint
                  outlined
                  dense
                >
                </v-file-input>
              </v-row>
              <v-row class="review-file" v-if="!showImage">
                <p class="uppercase">Upload Image File</p>
                <div class="review-file__btns">
                  <v-btn x-small color="primary" dark @click="openPdf(kitchenDataLocal.image)"> Review File </v-btn>
                  <v-btn x-small color="warning" dark @click="editImageFileSizes()"> Edit File </v-btn>
                </div>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6">
              <vue-tel-input-vuetify
                @onInput="onInput_contact_number"
                v-model="kitchenDataLocal.contact_person"
                defaultCountry="JO"
                :onlyCountries="['JO', 'SA']"
                @keypress="isNumber($event)"
                @paste.prevent
                :rules="[validators.validtelfun(kitchenDataLocal.contact_person, validtel)]"
                :maxLen="18"
                label="Mobile Number"
                placeholder="Mobile Number"
                class="uppercase"
              ></vue-tel-input-vuetify>
            </v-col>

            <v-col cols="12" sm="6">
              <vue-tel-input-vuetify
                @onInput="onInput_emergency_number"
                v-model="kitchenDataLocal.emergency_number"
                defaultCountry="JO"
                :onlyCountries="['JO', 'SA']"
                @keypress="isNumber($event)"
                @paste.prevent
                :rules="[validators.validtelfun(kitchenDataLocal.emergency_number, validtel2)]"
                :maxLen="18"
                label="Emergency Number"
                placeholder="Emergency Number"
                class="uppercase"
              ></vue-tel-input-vuetify>
            </v-col>

            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="kitchenDataLocal.work_days"
                  outlined
                  :items="working_days"
                  dense
                  multiple
                  :rules="[validators.required]"
                  item-text="day"
                  item-value="id"
                  label="Working Days"
                  placeholder="Working Days"
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y eager min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="kitchenDataLocal.start_work_time"
                      label="Start Work Time"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      :rules="[validators.required, momentValue('start')]"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <vuetify-time-select
                    v-model="kitchenDataLocal.start_work_time"
                    outlined
                    dense
                    :style="resolveBackGround()"
                    format="ampm"
                    hide-details="auto"
                  ></vuetify-time-select>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y eager min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="kitchenDataLocal.end_work_time"
                      label="End Work Time"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      :rules="[validators.required, momentValue('end')]"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <vuetify-time-select
                    v-model="kitchenDataLocal.end_work_time"
                    outlined
                    dense
                    :style="resolveBackGround()"
                    format="ampm"
                    hide-details="auto"
                  ></vuetify-time-select>
                </v-menu>
              </v-col>
            </v-row>

            <v-col cols="12">
              <h3 class="mt-6">Address:</h3>
            </v-col>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="kitchenDataLocal.latitude"
                  :rules="[validators.decimal_integer_Validator]"
                  outlined
                  dense
                  @input="canEdit = false"
                  hide-spin-buttons
                  label="Latitude"
                  placeholder="latitude"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="kitchenDataLocal.longitude"
                  :rules="[validators.decimal_integer_Validator]"
                  outlined
                  dense
                  @input="canEdit = false"
                  hide-spin-buttons
                  label="Longitude"
                  placeholder="longitude"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-6">
              <v-col cols="6">
                <v-text-field
                  v-model="kitchenDataLocal.street_name"
                  :rules="[validators.required]"
                  outlined
                  dense
                  hide-spin-buttons
                  label="Street Name"
                  placeholder="Street Name"
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="kitchenDataLocal.building_number"
                  :rules="[validators.required]"
                  outlined
                  dense
                  hide-spin-buttons
                  label="Building Number"
                  placeholder="Building Number"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-col cols="12">
              <TheMap
              :locations="{ latitude: kitchenDataLocal.latitude, longitude: kitchenDataLocal.longitude }"
                @resiveLatLng="resiveLatLng"
                :canEdit="canEdit"
                :resetMap.sync="resetMap"
                type="Edit"
                :data="kitchenData"
              ></TheMap>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="kitchenDataLocal.username"
                outlined
                dense
                :rules="[validators.required]"
                label="User Name"
                placeholder="User Name"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="kitchenDataLocal.password"
                type="password"
                :rules="kitchenDataLocal.password ? [validators.passwordValidator] : [true]"
                outlined
                autocomplete="new-password"
                dense
                label="Password"
                placeholder="Password"
                hide-details="auto"
                class="uppercase"
              >
              </v-text-field>
            </v-col>

            <v-card-text style="color: #ff3342" v-if="message">
              {{ message }}
            </v-card-text>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
                @click="cancel"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { integerValidator,decimal_integer_Validator, required, passwordValidator, validtelfun } from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { isString } from '@vueuse/shared'
import { mdiCamera } from '@mdi/js'
import TheMap from '../../TheMap.vue'
import moment from 'moment'

export default {
  components: { TheMap },
  data() {
    return {
      showHint: true,
    }
  },
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    kitchenData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hideHint() {
      this.showHint = false
    },
  },
  setup(props, { emit }) {
    const isBillingAddress = ref(true)
    const message = ref('')
    const validtel = ref(false)
    const validtel2 = ref(false)
    const kitchenDataLocal = ref({})
    const canEdit = ref(true)
    const resetMap = ref(false)
    const isEditedMap = ref(false)
    kitchenDataLocal.value = JSON.parse(JSON.stringify(kitchenDataLocal))

    const showImage = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }
    const cancel = () => {
      emit('update:is-bio-dialog-open', false)
      emit('refresh')
      resetMap.value = true
    }
    const resetForm = () => {
      form.value.reset()
    }
    const resolveBackGround = () => {
      if (localStorage.getItem('materio-active-theme') == 'light') {
        return 'background-color: #fff;'
      }

      if (localStorage.getItem('materio-active-theme') == 'dark') {
        return 'background-color: #28243D;'
      }
    }
    const resetkitchenData = () => {
      kitchenDataLocal.value = JSON.parse(JSON.stringify(kitchenDataLocal))
      resetForm()
    }
    const working_days = ref([
      { id: 1, day: 'Sun' },
      { id: 2, day: 'Mon' },
      { id: 3, day: 'Tue' },
      { id: 4, day: 'Wed' },
      { id: 5, day: 'Thu' },
      { id: 6, day: 'Sat' },
      { id: 7, day: 'Fri' },
    ])
    const openPdf = file => {
      console.log('file', file)
      window.open(file)
    }
    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]
    const languageOptions = ['English', 'Spanish', 'Portuguese', 'Russian', 'French', 'German']

    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        try {
          let end_work_time = moment(`Mon 03-Jul-2017, ${kitchenDataLocal.value.end_work_time}`).format('hh:mm A')
          kitchenDataLocal.value.end_work_time = end_work_time

          let start_work_time = moment(`Mon 03-Jul-2017, ${kitchenDataLocal.value.start_work_time}`).format('hh:mm A')
          kitchenDataLocal.value.start_work_time = start_work_time

          if (kitchenDataLocal.value.username == props.kitchenData.username) {
            delete kitchenDataLocal.value.username
          }
          
          await store.dispatch('app-kitchen/editkitchen', kitchenDataLocal.value)
          emit('refresh')
          emit('update:is-bio-dialog-open', false)
        } catch (error) {
          console.log('error', error)
          if (error.data.message) {
            Object.entries(error.data.message).forEach(err => {
              Swal.fire({
                icon: 'error',
                title: `${err[0]}`,
                text: `${err[1][0]}`,
                footer: 'Required Image dimensions: width:100px, height:100px, max-file-size: 500kb',
              })
            })
          }
        }
      } else {
        validate()
      }
    }
    const fileAccept = (accept, data) => {
      if (data && !isString(data)) {
        if (data.size > 500000) {
          return `MAX SIZE 500KB`
        }
        if (accept.includes(data.name.split('.')[1])) {
          return true
        } else {
          return `Please choose file path (${accept})`
        }
      }
      return true
    }
    const editImageFileSizes = () => {
      showImage.value = true
      kitchenDataLocal.value.image = null
    }
    const onInput_contact_number = ({ number, isValid }) => {
      if (number?.e164) {
        kitchenDataLocal.value.contact_person = number.e164
        validtel.value = isValid
      } else {
        validtel.value = false
      }
      let tester = /^(\+\d{0,}\s?\d{0,}\s?\d{0,}\s?\d{0,})?$/.test(number.input)
      let tester2 = /^(\+\d{0,}\s?\d{0,}[-]?\d{0,}[-]?\d{0,})?$/.test(number.input)
      if (!tester && !tester2) {
        validtel.value = false
      }
    }

    const onInput_emergency_number = ({ number, isValid }) => {
      if (number?.e164) {
        kitchenDataLocal.value.emergency_number = number.e164
        validtel2.value = isValid
      } else {
        validtel2.value = false
      }
      let tester = /^(\+\d{0,}\s?\d{0,}\s?\d{0,}\s?\d{0,})?$/.test(number.input)
      let tester2 = /^(\+\d{0,}\s?\d{0,}[-]?\d{0,}[-]?\d{0,})?$/.test(number.input)
      if (!tester && !tester2) {
        validtel2.value = false
      }
    }

    const resiveLatLng = mapCoordinates => {
      canEdit.value = true
      kitchenDataLocal.value.latitude = mapCoordinates.lat
      kitchenDataLocal.value.longitude = mapCoordinates.lng
      if (mapCoordinates.lat === '32.0000' && mapCoordinates.lng === '36.0000') {
        isEditedMap.value = false
      } else {
        isEditedMap.value = true
      }
    }

    const momentValue = (type) => {
      if (moment(new Date(`12-25-1995 ${kitchenDataLocal.value.end_work_time}`).toISOString()).isAfter(new Date(`12-25-1995 ${kitchenDataLocal.value.start_work_time}`).toISOString())) {
        return true
      } else if (type == 'start') {
        return 'Start time should before end time'
      }
      else if (type == 'end') {
        return 'End time should After start time'
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        showImage.value = false
        kitchenDataLocal.value = JSON.parse(JSON.stringify(props.kitchenData))
        kitchenDataLocal.value.work_days = kitchenDataLocal.value.work_days.map(ele => {
          let idV = 0
          switch (ele.toUpperCase()) {
            case 'SUN':
              idV = 1
              break
            case 'MON':
              idV = 2
              break
            case 'TUE':
              idV = 3
              break
            case 'WED':
              idV = 4
              break
            case 'THU':
              idV = 5
              break
            case 'SAT':
              idV = 6
              break
            case 'FRI':
              idV = 7
          }
          return idV
        })

        if (kitchenDataLocal.value.emergency_number) {
          validtel2.value = true
        }
        if (kitchenDataLocal.value.contact_person) {
          validtel.value = true
        }
      },
    )

    return {
      momentValue,
      cancel,
      resetMap,
      isEditedMap,
      resiveLatLng,
      canEdit,
      resolveBackGround,
      working_days,
      onInput_contact_number,
      onInput_emergency_number,
      validtel,
      validtel2,
      editImageFileSizes,
      showImage,
      openPdf,
      message,
      fileAccept,
      isBillingAddress,
      statusOptions,
      languageOptions,
      countries,
      kitchenDataLocal,
      resetkitchenData,
      form,
      valid,
      validate,
      onSubmit,
      icons: {
        mdiCamera,
      },
      // validation
      validators: {
        required,
        integerValidator,
        passwordValidator,
        validtelfun,
        decimal_integer_Validator,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.review-file {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &__btns {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.4rem;
  }
}
</style>
