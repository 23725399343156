import { render, staticRenderFns } from "./KitchenBioPanel.vue?vue&type=template&id=73282d5d&scoped=true&"
import script from "./KitchenBioPanel.vue?vue&type=script&lang=js&"
export * from "./KitchenBioPanel.vue?vue&type=script&lang=js&"
import style0 from "./KitchenBioPanel.vue?vue&type=style&index=0&id=73282d5d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73282d5d",
  null
  
)

export default component.exports